<template>
    <div class="back">
        <div class="agent-index">
            <div class="ft20 ftw500 cl-main">代理设置</div>
            <a-alert class="mt20" message="平台信息的设置，主要更改LOGO和版权信息" type="info" />
            <Form
            class="mt10"
            ref="form"
            :formSchema="formSchema"
            :labelWidth="135"
            >
            </Form>
            <div class="btn">
                <el-button type="primary" @click="onSave">保存设置</el-button>
            </div>
        </div>
    </div>
</template>

<script>
    import { formSchema } from './index'
    export default {
        data(){
            return {
                formSchema,
                loading: true,
            }
        },
        created(){
            
        },
        mounted(){
            this.onEcho()
        },
        methods: {
            onSave(){
                this.$refs.form.validate((is, data)=>{
                    if(is){
                        this.onRequest(data)
                    }
                })
            },
            onRequest(params){
                this.$http.api('api/Agent/AgentSiteEdit', params)
                .then(res=>{
                    this.$message.success(res.msg)
                })
            },
            onEcho(){
                this.$http.api('api/Install/InstallInfo', {type: 'Agent'})
                .then(res=>{
                    let data = {}
                    Object.keys(res.data.json_data).forEach(key=>{
                        data[key] = res.data.json_data[key].value
                    })
                    console.log(data);
                    this.$refs.form.setData(data)
                })
                .finally(()=>{
                    this.loading = false
                })
            }
        }
    }
</script>

<style lang="less" scoped>

    .back{
        background: #fff;
        height: 100%;
        padding: 20px;
    }
    .agent-index{
        width: 800px;
        .btn{
            padding-left: 135px;
            margin-top: 40px;
        }
    }
</style>