export const formSchema = [
    {
        field: 'agent_site_name',
        label: '代理后台名称',
        component: 'Input',
        ratio: 270,
        style: {
            width: '100%'
        }
    },
    {
        field: 'contact_Name',
        label: '客服名称',
        component: 'Input',
        ratio: 270,
        style: {
            width: '100%'
        }
    },
    {
        field: 'contact_Telephone',
        label: '客服电话',
        component: 'Input',
        ratio: 270,
        style: {
            width: '100%'
        }
    },
    {
        field: 'contact_QRcode',
        label: '客服二维码',
        component: 'Upload',
        style: {
            width: '100%'
        },
        componentProps: {
            desc: '代理后台使用'
        }
    },
    {
        field: 'logo_url',
        label: '代理后台登录LOGO',
        component: 'Upload',
        componentProps: {
            desc: '使用白色透明背景'
        }
    },
    {
        field: 'user_backend_url',
        label: '代理后台应用LOGO',
        component: 'Upload',
        componentProps: {
            desc: '使用白色透明背景'
        }
    },
]